var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-5 mx-table mt-4",attrs:{"shaped":"","tile":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3 flex-header"},[_c('div',{staticClass:"d-flex align-center filter-area"},[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('v-text-field',{staticClass:"search-input mb-4",attrs:{"hide-details":"","label":"広告施策名、広告施策タグ"},on:{"change":function($event){return _vm.changekeyWord($event)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('v-btn',{staticStyle:{"position":"absolute","right":"0","top":"12px","background":"#ffffff"},on:{"click":function($event){return _vm.searchField()}}},[_c('v-icon',{staticStyle:{"color":"#757575"}},[_vm._v("fas fa-search")])],1)],1),_c('div',{staticClass:"d-flex align-center mx-5"},[_c('span',{staticClass:"mr-3 custom-text"},[_vm._v("広告施策期間開始日時")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('DatePickerComponent',{attrs:{"type":"btn","date":_vm.dateFrom,"className":"bg--white ml-2 mr-8","attr":{
                  small: true,
                  outlined: true,
                },"action":on,"hasBtnResetDatePicker":true,"title":_vm.dateFormatted(_vm.dateFrom)},on:{"reset":function($event){_vm.changeDateFrom(null),
                    (_vm.dateFrom = null),
                    (_vm.menuDateFrom = true)}}})]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{staticClass:"v-date-picker-custom",attrs:{"first-day-of-week":0,"locale":_vm.$i18n.locale,"scrollable":""},on:{"change":function($event){return _vm.changeDateFrom($event)},"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('span',{staticClass:"mr-3"},[_vm._v("〜")]),_c('v-menu',{staticClass:"custom-menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('DatePickerComponent',{attrs:{"type":"btn","date":_vm.dateTo,"className":"bg--white ml-2 mr-8","attr":{
                  small: true,
                  outlined: true,
                },"action":on,"hasBtnResetDatePicker":true,"title":_vm.dateFormatted(_vm.dateTo)},on:{"reset":function($event){_vm.changeDateTo(null), (_vm.dateTo = null), (_vm.menuDateTo = true)}}})]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{staticClass:"v-date-picker-custom",attrs:{"first-day-of-week":0,"locale":_vm.$i18n.locale,"scrollable":""},on:{"change":function($event){return _vm.changeDateTo($event)},"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('div',{staticClass:"d-flex align-center mx-5"},[_c('span',{staticClass:"mx-5 custom-text"},[_vm._v("発送資料データ有無")]),_c('v-select',{staticClass:"cus-select",attrs:{"items":['指定無し', '有り', '無し']},on:{"change":function($event){return _vm.getHasAttachment($event)}}}),_c('v-select',{staticClass:"mx-3 cus-select-box",staticStyle:{"width":"230px"},attrs:{"label":"終了も表示","dense":"","outlined":"","items":_vm.itemsData.campaignList,"item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.changeFinished($event)}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('v-btn',{staticClass:"t-btn--prm mb-4 mt-1",attrs:{"disabled":!_vm.checkUser},on:{"click":function($event){_vm.openNewDialog = true}}},[_vm._v(" 新規広告施策作成 ")])],1),_c('Table',{ref:"table",attrs:{"attr":{
        'server-items-length': _vm.total,
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
        'class': 'mt-n4',
      },"multiSort":true,"itemsPerPage":10,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"headers":_vm.headers,"total":_vm.total,"items":_vm.campaignList,"funReset":_vm.getCampaignList,"filter":_vm.filter,"sortField":[]},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.status == 'Active')?_c('span',[_vm._v("対応中")]):_c('span',[_vm._v("終了")])]}},{key:"item.groups",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.groups.join(', ')))])]}},{key:"item.responseCount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.computed.responseCount))])]}},{key:"item.contractRate",fn:function(ref){
      var item = ref.item;
return [(item.computed.contractCount >= 0)?_c('span',[_vm._v(_vm._s(( item.computed.responseCount === 0 ? 0 : ( parseFloat( (item.computed.contractCount * 100) / item.computed.responseCount )) ).toFixed(1))+"%("+_vm._s(item.computed.contractCount)+"/"+_vm._s(item.computed.responseCount)+")")]):_c('span',[_vm._v(_vm._s('(' + item.computed.contractCount)+"/"+_vm._s(item.computed.responseCount + ')'))])]}},{key:"item.budget",fn:function(ref){
      var item = ref.item;
return [_c('tvos-jpy',{attrs:{"value":item.budget}})]}},{key:"item.ContractCPA",fn:function(ref){
      var item = ref.item;
return [(item.computed.contractCount > 0)?_c('tvos-jpy',{attrs:{"value":item.budget / item.computed.contractCount}}):_c('span',[_vm._v("-")])]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.startDate.replace(/-/g, '/') + ' ~ ' + item.endDate.replace(/-/g, '/')))])]}},{key:"item.advertisingData",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"attachment-col"},[_c('span-hover-pop',{attrs:{"value":_vm.showAttachment(
            item.attachment1,
            item.attachment2,
            item.attachment3
          )}})],1)]}},{key:"item.detail",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":""},on:{"click":function($event){(_vm.openDetailDialog = true), (_vm.idPolicy = parseInt(item.id))}}},[_c('ad',{attrs:{"witdh":25,"height":25}})],1)]}}],null,true)})],1),(_vm.openNewDialog)?_c('AdvertisingPoliciesDialog',{attrs:{"visible":_vm.openNewDialog},on:{"fetchList":function () { _vm.$refs.table.reset() },"close":function($event){_vm.openNewDialog = false}}}):_vm._e(),(_vm.openDetailDialog)?_c('AdvertisingPoliciesDetailDialog',{attrs:{"idPolicy":_vm.idPolicy},on:{"close":function($event){_vm.openDetailDialog = false},"fetchList":function () { _vm.$refs.table.reset() }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }