<template>
  <v-dialog
    v-model="dialog"
    @click:outside="resetForm"
    content-class="px-8 py-4"
  >
    <v-form ref="form">
      <h2 class="text--big mb-4" style="font-size: 28px; color: #212121">
        新規契約特典
      </h2>
      <label style="color: #000000">{{ $t('commons.contractStatus') }}</label>
      <v-select
        hide-details
        dense
        style="color: #000000 !important"
        :items="statusOptions"
        v-model="statusSelected"
        class="mb-4 sort-input custom"
      ></v-select>

      <label style="color: #000000">{{
        $t('advertising.policies.policiesName')
      }}</label>
      <v-text-field
        style="color: #000000 !important"
        v-model="name"
        :rules="[$rules.required, $rules.checkLenghInput(255)]"
        class="pa-0 mb-4"
      ></v-text-field>

      <!-- Date picker -->
      <h5 style="font-size: 10px !important; color: #000000" class="mb-1">
        期間
      </h5>
      <DatePickerSelectRangeFromTo
        :dates="dates"
        :attrTagInput="{
          readonly: true,
          type: 'text-field',
          color: '#000000',
          class: 'date-text pa-0 w-100',
          hasBtnResetDatePicker: true
        }"
        @setDates="(value) => {dates = value}"
        @reset="() => {dates = []}"
        :icon="true"
        :rules="[rules.isRequiredDatePickerRange]"
      ></DatePickerSelectRangeFromTo>
      <!-- End - datepicker -->
      <div class="my-5">
        <v-select
          hide-details
          dense
          :items="typeList"
          v-model="typeSelected"
          item-text="text"
          item-value="value"
          class="sort-input custom"
          label="特典種別"
        ></v-select>
      </div>

      <label style="color: #000000">{{
        $t('advertising.contractSpecial.contractBenefits')
      }}</label>
      <v-textarea
        class="mt-2 rounded-lg"
        rows="5"
        outlined
        v-model="description"
        :rules="[rules.isRequired, $rules.checkLenghInput(2000)]"
      ></v-textarea>

      <!-- ---------------------------- -->
      <div class="elevation-4 rounded pa-5 mt-3">
        <v-checkbox
          hide-details
          v-model="isFillChecked"
          class="mr-3 mb-4 checkbox-custom"
          @change="checkFill"
          :label="$t('advertising.contractSpecial.fillChecbox')"
        ></v-checkbox>

        <label
          style="color: #000000 !important"
          :class="{ 'text--inactive': !isFillChecked }"
          >{{ $t('advertising.contractSpecial.numericalValue') }}</label
        >
        <v-text-field
          v-model="amount"
          :disabled="!isFillChecked"
          :rules="[rules.maxInt]"
          class="pa-0 w-50"
        ></v-text-field>
      </div>

      <!-- ---------------------------- -->

      <div class="elevation-4 rounded pa-5 mt-3">
        <v-checkbox
          hide-details
          v-model="isGenerateChecked"
          @change="checkGenerate"
          class="mr-3 checkbox-custom"
          :label="$t('advertising.contractSpecial.issueCodeAfterJoiningVS')"
        ></v-checkbox>
        <div class="d-flex align-center my-5">
          <div class="d-flex justify-start">
            <v-btn
              @click="generateSignupBonusVsPromoCode"
              :disabled="!isGenerateChecked"
              class="t-btn--prm mr-4"
              >コードを生成</v-btn
            >
            <div>
              <h5
                style="color: #000000 !important"
                :class="{ 'text--inactive': !isGenerateChecked }"
              >
                特典コード
              </h5>
              <span style="color: #000000 !important">
                {{ signupBonusVsPromoCode }}
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-center my-5">
          <label
            style="color: #444444 !important"
            class="normal mr-3 mt-n5"
            :class="{ 'text--inactive': !isGenerateChecked }"
            >{{ $t('advertising.contractSpecial.entryValue') }}</label
          >
          <v-radio-group
            v-model="vsTypeSelected"
            :rules="(isGenerateChecked && [rules.isRequired]) || []"
            :disabled="!isGenerateChecked"
            row
          >
            <v-radio
              class="radio-custom"
              :label="$t('advertising.contractSpecial.discountMembership')"
              value="vsDiscount"
            ></v-radio>
            <v-radio
              class="radio-custom"
              :label="$t('advertising.contractSpecial.tokyuPointsGranted')"
              value="vsTvp"
            ></v-radio>
          </v-radio-group>
        </div>

        <label
          style="color: #000000"
          :class="{ 'text--inactive': !isGenerateChecked }"
          >{{ $t('advertising.contractSpecial.numericalValue') }}</label
        >
        <v-text-field
          v-model="vsType"
          :rules="(isGenerateChecked && [rules.isRequired, rules.maxInt]) || []"
          :disabled="!isGenerateChecked"
          class="pa-0 w-50"
        ></v-text-field>
        <v-checkbox
          hide-details
          v-model="canUseInMemberWeb"
          class="mr-3 mb-4 checkbox-custom"
          label="会員WEBで利用可能"
        />
        <v-checkbox
          hide-details
          v-model="canUseInPromoUrl"
          class="mr-3 mb-4 checkbox-custom"
          label="プロモーションURLから利用可能"
        />
      </div>

      <!-- ---------------------------- -->

      <div class="text-right mt-5">
        <v-btn @click="resetForm" class="t-btn--red-dark">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn @click="submitForm" class="t-btn--prm ml-4">{{
          $t('buttons.save')
        }}</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { nextOrPrevDay } from '@/utils/dateUtil';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { MAX_INT } from '@/constants/enum';
import DatePickerSelectRangeFromTo from '@/components/Input/datePicker/DatePickerSelectRangeFromTo.vue';
import { validDatePickerRange } from '@/utils/validate';

const defaultData = () => {
  return {
    row: null,
    dates: [],
    statusSelected: 'Active',
    typeSelected: 'Discount',
    name: null,
    description: null,
    isFillChecked: false,
    amount: null,
    isGenerateChecked: false,
    vsTypeSelected: null,
    vsType: null,
    canUseInMemberWeb: true,
    canUseInPromoUrl: false,
  }
}

export default {
  name: 'ContractSpecialDialog',
  components: {
    DatePickerSelectRangeFromTo,
  },
  data() {
    return {
      ...defaultData(),
      statusOptions: [
        {
          text: '対応中',
          value: 'Active',
        },
        {
          text: '対応済',
          value: 'Ended',
        },
      ],
      typeList: [
        {
          text: '値引き',
          value: 'Discount',
        },
        {
          text: 'ポイント付与',
          value: 'Point',
        },
        {
          text: '商品券',
          value: 'Voucher',
        },
        {
          text: 'マイル付与',
          value: 'Mile',
        },
      ],
      rules: {
        isRequired: (value) => !!value || this.$t('rules.isRequired'),
        isRequiredDatePickerRange: value => validDatePickerRange(value) || this.$t('rules.isRequired'),
        maxInt: v => v < MAX_INT ||  this.$t('rules.isInvalid')
      }
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters(['signupBonusVsPromoCode']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['generateSignupBonusVsPromoCode', 'createSignupBonus']),
    ...mapMutations([
      'setSignupBonusVsPromoCode',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    nextOrPrevDay,
    async submitForm() {
      if (this.$refs.form.validate()) {
        const vsDiscount =
          this.vsTypeSelected === 'vsDiscount' ? this.vsType : 0;
        const vsTvp = this.vsTypeSelected === 'vsTvp' ? this.vsType : 0;
        await this.createSignupBonus({
          status: this.statusSelected,
          name: this.name,
          startDate: this.dates[0],
          endDate: this.dates[1],
          type: this.typeSelected,
          description: this.description,
          amount: this.amount,
          code: this.signupBonusVsPromoCode,
          vsTvp,
          vsDiscount,
          canUseInMemberWeb: this.canUseInMemberWeb,
          canUseInPromoUrl: this.canUseInPromoUrl,
        });
        this.$emit('fetchData');
        this.setSignupBonusVsPromoCode(null);
        this.resetForm();
        this.$emit('close');
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      const data = defaultData();
      Object.entries(data).forEach(([key, value]) => {
        this[key] = value;
      });
      this.setSignupBonusVsPromoCode(null);
      this.$emit('close');
    },
    checkFill(v) {
      if (!v) {
        this.amount = null;
      }
    },
    checkGenerate(v) {
      if (!v) {
        this.setSignupBonusVsPromoCode(null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mdi-calendar-month{
    margin: 0px 5px 15px 5px;
  }
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 600px;
  }
  .custom {
    .v-select__selection {
      color: #000000 !important;
    }
  }
  .radio-custom {
    .v-label {
      color: #444444;
    }
  }
  .checkbox-custom {
    .v-label {
      color: #444444;
    }
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text {
      max-width: 250px !important;
      input {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
}
</style>