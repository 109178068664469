<template>
  <div>
    <div>
      <h1 class="page-title-list t-header">
        {{ $t('advertising.title') }}
      </h1>
      <template>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="#13ACE0"></v-tabs-slider>
          <v-tab v-for="item in tabNames" :key="item" class="page-title-tab">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabNames" :key="item">
            <AdvertisingPoliciesList v-if="tab === 0" />
            <ContractSpecialList v-if="tab === 1" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>
<script>
import AdvertisingPoliciesList from '@/views/advertising/policies/advertisingPoliciesList';
import ContractSpecialList from '@/views/advertising/contractSpecial/contractSpecialList';

export default {
  name: 'AdvertisingPoliciesTab',
  data() {
    return {
      tab: 0,
      tabNames: [
        this.$t('advertising.tabs.policies'),
        this.$t('advertising.tabs.contractSpecial'),
      ],
    };
  },
  components: {
    AdvertisingPoliciesList,
    ContractSpecialList,
  },
};
</script>

<style lang="scss" scoped>
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}

.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
}

.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}

.btn-icon {
  background-color: #13ace0;
  margin-top: 12px;
}

.v-label {
  font-size: 12px !important;
}
::v-deep{
  .v-data-footer__pagination{
    display: none !important;
  }
}
</style>
