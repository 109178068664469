<template>
  <div>
    <v-card class="pa-5 mx-table mt-4" shaped tile>
      <div class="d-flex justify-space-between align-center mb-10">
        <div class="d-flex align-center">
          <v-text-field
            hide-details
            v-model="keyword"
            :label="$t('advertising.search')"
            append-icon="mdi-magnify"
            class="search-input pa-0 mr-5"
            @change="
              () => {
                $refs.table.reset();
              }
            "
          ></v-text-field>
          <v-checkbox
            hide-details
            v-model="status"
            @change="handleChangeStatus"
            :label="$t('advertising.policies.alsoShowsSupported')"
          ></v-checkbox>
        </div>
        <v-btn class="t-btn--prm" :disabled="!checkUser" @click="visible = 2">
          {{ $t('buttons.createNewContractBenefits') }}
        </v-btn>
      </div>
      <Table
        :attr="{
          'server-items-length': totalOfSignUpBonusAppList,
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'mt-n4',
        }"
        ref="table"
        :multiSort="true"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :total="totalOfSignUpBonusAppList"
        :items="signUpBonusList"
        :funReset="fetchData"
      >
        <template v-slot:[`item.bonusStatus`]="{ item }">
          {{ item.status === 'Active' ? '対応中' : '対応済み' }}
        </template>
        <template v-slot:[`item.period`]="{ item }">
          {{
            item.startDate.split('-').join('/') +
              ' ~ ' +
              item.endDate.split('-').join('/')
          }}
        </template>
        <template v-slot:[`item.numericType`]="{ item }">
          {{
            item.type === 'Discount'
              ? '値引き'
              : item.type === 'Point'
              ? 'ポイント付与'
              : item.type === 'Voucher'
              ? '商品券'
              : item.type === 'Mile'
              ? 'マイル付与'
              : ''
          }}
        </template>
        <template v-slot:[`item.numericalValue`]="{ item }">
          {{ item.amount || '' }}
        </template>
        <template v-slot:[`item.targetCustomerNum`]="{ item }">
          {{ item.computed && item.computed.clientCount }}
        </template>
        <template v-slot:[`item.contractNum`]="{ item }">
          {{ item.computed && item.computed.contractCount }}
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn @click="showPrivilegeDialog(item.id)" class="t-btn--prm" icon>
            <v-icon small>fas fa-ad</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
    <contract-special-dialog
      :visible="visible === 2"
      @close="visible = -1"
      @fetchData="
        () => {
          $refs.table.reset();
        }
      "
    ></contract-special-dialog>
    <contract-privilege-name
      :visible="visible === 1"
      @close="visible = -1"
      :id="currentId"
      @fetchData="
        () => {
          $refs.table.reset();
        }
      "
    ></contract-privilege-name>
  </div>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { i18n } from '@/plugins/i18n';
import ContractSpecialDialog from './contractSpecialDialog.vue';
import ContractPrivilegeName from './ContractPrivilegeName.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Table from '@/components/Table/index.vue';

export default {
  name: 'ContractSpecialList',
  data() {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      status: false,
      keyword: null,
      currentId: null,
      headers: [
        {
          text: i18n.t('advertising.contractSpecial.contractBenefitsDetails'),
          value: 'detail',
          width: '80px',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('commons.contractStatus'),
          value: 'bonusStatus',
          fieldName: 'signupBonus.status',
        },
        {
          text: i18n.t('advertising.contractSpecial.benefitName'),
          value: 'name',
          fieldName: 'signupBonus.name',
        },
        {
          text: i18n.t('commons.period'),
          value: 'period',
          fieldName: 'signupBonus.startDate',
        },
        {
          text: i18n.t('commons.benefits'),
          value: 'description',
          fieldName: 'signupBonus.description',
        },
        {
          text: '特典種別',
          value: 'numericType',
          fieldName: 'signupBonus.type',
        },
        {
          text: i18n.t('advertising.contractSpecial.numericalValue'),
          value: 'numericalValue',
          fieldName: 'signupBonus.amount',
        },
        {
          text: i18n.t('advertising.contractSpecial.automaticAfterJoiningVS'),
          value: 'vsBonuses',
          sortable: false,
        },
        {
          text: i18n.t('advertising.contractSpecial.pointOrFee'),
          value: 'vsBonusValues',
          sortable: false,
        },
        {
          text: i18n.t('advertising.contractSpecial.targetCustomerNum'),
          value: 'targetCustomerNum',
          fieldName: 'computed.clientCount',
        },
        {
          text: i18n.t('advertising.contractSpecial.numberOfContracts'),
          value: 'contractNum',
          fieldName: 'computed.contractCount',
        },
      ],
      visible: -1,
    };
  },

  mounted() {
    this.$refs.table.reset();
  },
  prop: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters(['signUpBonusList', 'totalOfSignUpBonusAppList']),
  },
  methods: {
    ...mapActions([
      'fetchSignUpBonusList',
      'fetchSignUpBonusDetail',
    ]),
    ...mapMutations([
      'setSignUpBonusPagiTake',
      'setSignUpBonusPagiSkip',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    handleChangeStatus(value) {
      this.$refs.table.reset();
    },

    closeDialog() {
      this.$emit('close');
    },

    async fetchData(variables) {
      await this.fetchSignUpBonusList({
        status: this.status,
        keyword: this.keyword,
        pagi: variables.pagination,
        orderBy: variables.orderBy
      });
    },

    async showPrivilegeDialog(id) {
      await this.fetchPrivilegeDialogData(id);
      this.currentId = id;
      this.visible = 1;
    },
    
    fetchPrivilegeDialogData(id) {
      this.fetchSignUpBonusDetail(id);
    },
  },
  components: {
    ContractSpecialDialog,
    ContractPrivilegeName,
    Table,
  },
};
</script>

<style lang="scss" scoped>
// .search-input {
//   width: 353px;
// }
// ::v-deep{
//   .v-text-field__slot{
//     input,
//     .v-label{
//       color: #757575 !important;
//       font-size: 15px !important;
//     }
//   }
//   .v-input__slot{
//     .v-label{
//       color: #000000 !important;
//       font-size: 12px !important;
//       font-weight: 500;
//     }
//   }
//   .v-data-table-header{
//     tr{
//       th{
//         color: #757575 !important;
//         font-size: 14px !important;
//         font-weight: bold !important;
//       }
//     }
//   }
//   tbody{
//     tr{
//       td{
//         color: #767676 !important;
//         font-size: 16px !important;
//       }
//     }
//   }
//   .v-data-footer{
//     &__pagination{
//       display: none !important;
//       color: red !important;
//     }
//     .v-select__selection--comma,
//     .v-data-footer__select{
//       color: #000000 !important;
//       font-size: 13px !important;
//       font-weight: 500;
//     }
//     .v-icon__svg{
//       color: #333 !important;
//     }
//   }
// }
</style>
