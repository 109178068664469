<template>
  <div>
    <v-card class="pa-5 mx-table mt-4" shaped tile>
      <div class="d-flex justify-space-between align-center mb-3 flex-header">
        <div class="d-flex align-center filter-area">
          <div class="d-flex" style="position: relative">
            <v-text-field
              hide-details
              @change="changekeyWord($event)"
              v-model="keyword"
              label="広告施策名、広告施策タグ"
              class="search-input mb-4"
            ></v-text-field>
            <v-btn
              style="
                position: absolute;
                right: 0;
                top: 12px;
                background: #ffffff;
              "
              @click="searchField()"
            >
              <v-icon style="color: #757575">fas fa-search</v-icon>
            </v-btn>
          </div>

          <div class="d-flex align-center mx-5">
            <!-- Date picker -->
            <span class="mr-3 custom-text">広告施策期間開始日時</span>
            <!-- Date picker -->
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <DatePickerComponent
                  type="btn"
                  :date="dateFrom"
                  className="bg--white ml-2 mr-8"
                  :attr="{
                    small: true,
                    outlined: true,
                  }"
                  :action="on"
                  @reset="
                    changeDateFrom(null),
                      (dateFrom = null),
                      (menuDateFrom = true)
                  "
                  :hasBtnResetDatePicker="true"
                  :title="dateFormatted(dateFrom)"
                ></DatePickerComponent>
              </template>
              <v-date-picker
                v-model="dateFrom"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @change="changeDateFrom($event)"
                @input="menuDateFrom = false"
                class="v-date-picker-custom"
              ></v-date-picker>
            </v-menu>
            <span class="mr-3">〜</span>
            <!-- Date picker -->
            <v-menu
              class="custom-menu"
              v-model="menuDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <DatePickerComponent
                  type="btn"
                  :date="dateTo"
                  className="bg--white ml-2 mr-8"
                  :attr="{
                    small: true,
                    outlined: true,
                  }"
                  :action="on"
                  @reset="
                    changeDateTo(null), (dateTo = null), (menuDateTo = true)
                  "
                  :hasBtnResetDatePicker="true"
                  :title="dateFormatted(dateTo)"
                ></DatePickerComponent>
              </template>
              <v-date-picker
                v-model="dateTo"
                @change="changeDateTo($event)"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @input="menuDateTo = false"
                class="v-date-picker-custom"
              ></v-date-picker>
            </v-menu>
            <!--  -->
          </div>
          <div class="d-flex align-center mx-5">
            <span class="mx-5 custom-text">発送資料データ有無</span>
            <v-select
              class="cus-select"
              @change="getHasAttachment($event)"
              :items="['指定無し', '有り', '無し']"
            >
            </v-select>
            <v-select
              style="width: 230px"
              class="mx-3 cus-select-box"
              label="終了も表示"
              @change="changeFinished($event)"
              dense
              outlined
              :items="itemsData.campaignList"
              item-text="name"
              item-value="id"
              v-model="status"
            ></v-select>
          </div>
        </div>
        <v-btn
          class="t-btn--prm mb-4 mt-1"
          :disabled="!checkUser"
          @click="openNewDialog = true"
        >
          新規広告施策作成
        </v-btn>
      </div>
      <Table
        :attr="{
          'server-items-length': total,
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          'class': 'mt-n4',
        }"
        ref="table"
        :multiSort="true"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :total="total"
        :items="campaignList"
        :funReset="getCampaignList"
        :filter="filter"
        :sortField="[]"
      >
        <template v-slot:[`item.status`]="{ item }">
          <span v-if="item.status == 'Active'">対応中</span>
          <span v-else>終了</span>
        </template>

        <template v-slot:[`item.groups`]="{ item }">
          <span>{{ item.groups.join(', ') }}</span>
        </template>

        <template v-slot:[`item.responseCount`]="{ item }">
          <span>{{ item.computed.responseCount }}</span>
        </template>

        <template v-slot:[`item.contractRate`]="{ item }">
          <span v-if="item.computed.contractCount >= 0"
            >{{
              (
                item.computed.responseCount === 0 ? 0 : (
                  parseFloat(
                    (item.computed.contractCount * 100) /
                      item.computed.responseCount,
                  ))
              ).toFixed(1)
            }}%({{ item.computed.contractCount }}/{{
              item.computed.responseCount
            }})</span
          >
          <span v-else
            >{{ '(' + item.computed.contractCount }}/{{
              item.computed.responseCount + ')'
            }}</span
          >
        </template>

        <template v-slot:[`item.budget`]="{ item }">
          <tvos-jpy :value="item.budget" />
        </template>

        <template v-slot:[`item.ContractCPA`]="{ item }">
          <tvos-jpy
            v-if="item.computed.contractCount > 0"
            :value="item.budget / item.computed.contractCount"
          />
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{
            item.startDate.replace(/-/g, '/') +
              ' ~ ' +
              item.endDate.replace(/-/g, '/')
          }}</span>
        </template>

        <template v-slot:[`item.advertisingData`]="{ item }">
          <div class="attachment-col">
            <span-hover-pop :value="showAttachment(
              item.attachment1,
              item.attachment2,
              item.attachment3,
            )"/>
          </div>
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn
            @click="(openDetailDialog = true), (idPolicy = parseInt(item.id))"
            class="t-btn--prm"
            icon
          >
            <ad :witdh="25" :height="25" />
          </v-btn>
        </template>
      </Table>
    </v-card>
    <AdvertisingPoliciesDialog
      v-if="openNewDialog"
      :visible="openNewDialog"
      @fetchList="() => { $refs.table.reset() }"
      @close="openNewDialog = false"
    />
    <AdvertisingPoliciesDetailDialog
      v-if="openDetailDialog"
      @close="openDetailDialog = false"
      :idPolicy="idPolicy"
      @fetchList="() => { $refs.table.reset() }"
    />
  </div>
</template>

<script>
import DatePickerComponent from '@/components/Input/DatePicker.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import AdvertisingPoliciesDialog from './advertisingPoliciesDialog';
import AdvertisingPoliciesDetailDialog from './advertisingPoliciesDetailDialog';
import { i18n } from '@/plugins/i18n';
import moment from 'moment';
import Ad from '@/components/icons/ad.vue';
import { CAMPAIGN_LIST } from '@/api/graphql/advertising/advertising';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { mapMutations } from 'vuex';
import gql from 'graphql-tag';
import Table from '@/components/Table/index.vue';
import SpanHoverPop from '@/components/shared/SpanHoverPop.vue';

export default {
  name: 'AdvertisingPoliciesList',
  data() {
    return {
      listSortField: [
        {
          colName: 'id', // === value in headers
          name: 'id',
          fieldName: 'campaign'
        },
        {
          colName: 'startDate', // === value in headers
          name: 'startDate',
          fieldName: 'campaign'
        }
      ],
      total: null,
      status: false,
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDateFrom: false,
      menuDateTo: false,
      headers: [
        {
          text: i18n.t('advertising.policies.detail'),
          value: 'detail',
          width: '80px',
          align: 'center',
          sortable: false,
        },
        {
          text: i18n.t('commons.contractStatus'),
          value: 'status',
          fieldName: 'campaign.status'
        },
        {
          text: i18n.t('advertising.title') + 'ID',
          value: 'id',
          fieldName: 'campaign.id',
          width: '130px',
        },
        {
          text: i18n.t('advertising.policies.advertisingName'),
          value: 'name',
          fieldName: 'campaign.name',
        },
        {
          text: '広告施策タグ',
          value: 'groups',
          fieldName: 'campaign.groups',
        },
        {
          text: i18n.t('advertising.policies.customerNum'),
          value: 'responseCount',
          fieldName: 'computed.responseCount',
        },
        {
          text: i18n.t('advertising.policies.contractRate'),
          value: 'contractRate',
          fieldName: 'computed.contractCount',
        },
        {
          text: i18n.t('advertising.policies.cost'),
          value: 'budget',
          fieldName: 'campaign.budget',
        },
        {
          text: '成約CPA',
          value: 'ContractCPA',
          sortable: false,
        },
        {
          text: '広告施策期間',
          value: 'startDate',
          width: '140px',
          fieldName: 'campaign.startDate',
        },
        {
          text: i18n.t('advertising.policies.advertisingData'),
          value: 'advertisingData',
          width: '300px',
          sortable: false,
        },
      ],
      itemsData: {
        campaignList: [
          {
            id: false,
            name: '対応中かつ期限内を表示',
          },
          {
            id: null,
            name: '終了や期限切れも表示',
          },
          {
            id: true,
            name: '期限切れのみ表示',
          },
        ],
      },
      campaignList: [],
      keyword: '',
      dateFrom: null,
      dateTo: null,
      statusHasAttachment: false,
      filter: {
        dateFrom: null,
        dateTo: null,
        finished: false,
      },
      // Dialog----------------
      openNewDialog: false,
      openDetailDialog: false,
      idPolicy: null,
      // ----------------------
    };
  },
  mounted() {
    this.$refs.table.reset();
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    dateFormatted(date) {
      return date ? moment(date).format('yyyy年MM月DD日') : '';
    },

    changekeyWord(event) {
      if (event !== '') {
        this.filter.keyword = this.keyword;
      } else {
        delete this.filter.keyword;
      }
      this.$refs.table.reset();
    },

    searchField() {
      this.$refs.table.reset();
    },

    changeDateFrom(event) {
      if (this.filter.dateFrom === event) {
        this.filter.dateFrom = null;
        this.dateFrom = null;
      } else {
        this.filter.dateFrom = event;
      }
      this.$refs.table.reset();
    },

    changeDateTo(event) {
      if (this.filter.dateTo === event) {
        this.filter.dateTo = null;
        this.dateTo = null;
      } else {
        this.filter.dateTo = event;
      }
      this.$refs.table.reset();
    },

    getHasAttachment(event) {
      this.statusHasAttachment = true;
      if (event === '指定無し') {
        this.filter.hasAttachment = null;
      } else if (event === '有り') {
        this.filter.hasAttachment = true;
      } else {
        this.filter.hasAttachment = false;
      }
      this.$refs.table.reset();
    },

    changeFinished(event) {
      if (event) {
        this.filter.finished = true;
      } else if (event === null) {
        this.filter.finished = null;
      } else {
        this.filter.finished = false;
      }
      this.$refs.table.reset();
    },

    showAttachment(attachment1, attachment2, attachment3) {
      const files = [attachment1, attachment2, attachment3];
      return files
        .map(attch => this.$options.filters.filename(attch))
        .filter(v => !!v)
        .join(', ');
    },

    async getCampaignList(variables) {
      await this.$apollo
        .query({
          query: gql`
            ${CAMPAIGN_LIST}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.campaignList = data.data.campaignList.items;
          this.total = data.data.campaignList.total;
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              CAMPAIGN_LIST,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
  },
  components: {
    Ad,
    AdvertisingPoliciesDialog,
    AdvertisingPoliciesDetailDialog,
    DatePickerComponent,
    Table,
    SpanHoverPop,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .btn-date {
    min-width: 80px !important;
    font-size: 10px !important;
    .v-btn__content {
      color: #000 !important;
      font-weight: 500;
    }
  }
  .cus-select {
    .v-select__slot {
      .v-select__selection--comma {
        font-size: 14px !important;
        font-weight: 500;
        color: #000000;
        margin: 7px 4px 0px 0 !important;
      }
    }
  }
  .cus-select .v-select__slot {
    max-width: 200px;
    width: 200px;
  }

  .cus-select-box {
    .v-input__slot {
      margin: 0;
      max-height: 32px;
      min-height: 32px !important;
      display: flex !important;
      align-items: center !important;
      width: 200px;
      fieldset {
        color: #000000 !important;
      }
    }
    margin-top: 18px;
    label {
      color: #000000 !important;
      font-size: 12px !important;
      font-weight: 500;
    }
    .v-select__selection--comma {
      font-size: 12px !important;
      color: #000000 !important;
      font-weight: 500;
    }
  }
//   .v-data-table {
//     thead {
//       tr th {
//         color: #757575 !important;
//         font-size: 14px !important;
//         font-weight: bold !important;
//       }
//     }
//     tbody {
//       tr td {
//         color: #757575 !important;
//         font-size: 16px !important;
//       }
//     }
//   }
  .search-input {
    .v-text-field__slot {
      .v-label {
        font-size: 15px !important;
        color: #757575 !important;
      }
      input {
        font-size: 15px !important;
        color: #757575 !important;
      }
    }
  }
  .fa-search {
    width: 19px !important;
    height: 19px !important;
    color: #757575 !important;
  }
  .mdi-calendar-month {
    width: 14px !important;
    height: 16px !important;
    color: #333333 !important;
  }
  .t-btn--prm {
    .v-btn__content {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
//   .v-btn--round {
//     width: 25px !important;
//     height: 25px !important;
//     .v-btn__content {
//       svg {
//         height: 14px !important;
//         width: 14px !important;
//       }
//     }
//   }
//   .v-data-footer__select {
//     color: #000 !important;
//     font-weight: 500;
//     font-size: 13px !important;
//     .v-select__selection--comma {
//       color: #000 !important;
//       font-weight: 500;
//       font-size: 13px !important;
//     }
//     .v-icon__svg {
//       color: #333 !important;
//     }
//   }

//   .v-data-footer__icons-after,
//   .v-data-footer__icons-before {
//     .v-icon__svg {
//       color: #333 !important;
//     }
//   }
//   .attachment-col {
//     width: 300px;
//     overflow: auto;
//   }
// }
  .search-input {
    width: 353px;
  }
  .custom-text {
    color: #000000 !important;
    font-size: 12px !important;
    font-weight: 500;
  }
  .filter-area {
    max-width: 1000px;
    flex-wrap: wrap;
  }
}
</style>
